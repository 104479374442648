@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

.root {
  z-index: 3;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  color: black;
  padding: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @include page-width(sm-up) {
    padding: 1rem;
  }

  @include page-width(md-up) {
    padding: 2rem;
  }

  @include page-width(xl-up) {
    padding: 3rem;
  }

  div {
    justify-content: space-between;
  }

  .corner {
    width: 5rem;
    height: 5rem;
    border-color: black;
    border-style: solid;
    border-width: 0px;
  }

  .corner-top-left {
    border-left-width: 2px;
    border-top-width: 2px;
  }

  .corner-top-right {
    border-right-width: 2px;
    border-top-width: 2px;
  }

  .corner-bottom-left {
    border-left-width: 2px;
    border-bottom-width: 2px;
  }

  .corner-bottom-right {
    border-right-width: 2px;
    border-bottom-width: 2px;
  }

  &.invert {
    color: white;

    .corner {
      border-color: white;
    }
  }

  &.no-image {
    padding: 2rem;
  }
}

@use 'sass:math';
@import '../../../styles/resources/colors';
@import '../../../styles/resources/variables';
@import '../../../styles/resources/mixins';

.root {
  background-color: white;
}

.content {
  background-color: white;
}

.image {
  background-color: white;
}

@use 'sass:math';
@import '../../../styles/resources/colors';
@import '../../../styles/resources/variables';
@import '../../../styles/resources/mixins';

.root {
  .banner {
    position: relative;
    height: 0;
    padding-bottom: 100%;

    .content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 4;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      &.invert {
        color: white;
      }
    }

    .content-inner {
      margin: 0 auto;
      max-width: 100%;

      @include page-width(sm-up) {
        max-width: 40rem;
      }
    }
  }
}

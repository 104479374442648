@use 'sass:math';
@import '../../../styles/resources/colors';
@import '../../../styles/resources/variables';
@import '../../../styles/resources/mixins';

.root {
  color: black;
  background-color: white;
}

.invert {
  color: white;
  background-color: black;
}

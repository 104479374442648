@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

// price

.price {
  color: black;
  font-family: $font_heading;
  font-weight: bold;

  &.large {
    del {
      font-size: 70%;
    }
  }

  ins {
    text-decoration: none;
    color: theme-color('highlight');
  }

  del {
    font-weight: normal;
    margin-left: 1rem;
    font-size: 85%;
  }
}

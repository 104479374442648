@use 'sass:math';
@import '../../../styles/resources/colors';
@import '../../../styles/resources/variables';
@import '../../../styles/resources/mixins';

.root {
  background-color: white;
}

.tab {
  > ul {
    > li {
      > a {
        @include size-h4;
        font-weight: 900;
        text-transform: uppercase;
      }
    }
  }
}

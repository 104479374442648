@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

.root {
  font-family: 'Lato', sans-serif;
  height: 45px;
}

.borderless {
  .selected {
    height: 100%;
    border: none;
  }
}

.selected {
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 0;
  border: 1px solid theme-color('mid-grey');
  color: black;
  display: flex;
  align-items: center;
  padding: 1.9rem 1.5rem;

  svg {
    margin-left: 20px;
  }

  input {
    flex: 1 1 0;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
    user-select: none;
    pointer-events: none;
    caret-color: transparent;
  }
}

.pre {
  color: #666;
  margin-right: 5px;
}

.options {
  display: none;
  position: relative;
  top: -1px;
  border: 1px solid #aaa;
  border-radius: 0;
  z-index: 2;
  background-color: white;
  color: black;
}

.open {
  display: block;
}

.option {
  cursor: pointer;
  padding: 8px 8px;

  &:hover {
    background-color: #f9f9f9;
  }
}

@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

.root {
  position: relative;

  video {
    width: 100%;
    max-width: 100%;
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  video {
    height: 100%;
    object-fit: cover;
  }
}

@use 'sass:math';
@import '../../../styles/resources/colors';
@import '../../../styles/resources/variables';
@import '../../../styles/resources/mixins';

.root {
  position: relative;
  background-color: theme-color('primary');

  .news-container {
    position: relative;
    z-index: 1;

    @include page-width(sm-down) {
      padding: 2rem;
    }
  }

  .title {
    color: white;
    line-height: 1;

    span {
      background-color: black;
    }
  }
}

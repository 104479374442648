@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

// Base element
.root {
  display: block;
  height: 100%;

  // Inner wrapper
  .wrap {
    display: flex;
    flex-flow: column;
    height: 100%;

    @include page-width(sm-up) {
      &:hover {
        .button,
        .colors {
          opacity: 1;
        }

        .image {
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  // Image wrapper
  .image {
    position: relative;
    padding-bottom: 115%;
    background-color: #e6e6e6;
    height: 0;

    &::before {
      content: '';
      background-color: rgba(0, 0, 0, 0.3);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 0.3s;
      z-index: 4;
    }
  }

  .button,
  .colors {
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
  }

  // Button
  .button {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;
  }

  // Colours
  .colors {
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem 1.5rem;
    z-index: 5;
    display: none;

    @include page-width(xl-up) {
      display: block;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      list-style-type: none;
      padding: 0;
      margin: 0 -0.2rem;

      li {
        padding: 0.2rem;
      }
    }
  }

  // Images
  .image-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  // Content wrapper
  .content {
    position: relative;
    border-bottom: 2px solid black;
    padding-bottom: 0.5rem;
    padding-top: 1rem;
    flex: 1;
  }

  // Icon
  .icon {
    position: absolute;
    right: 0;
    top: 0.6rem;

    svg {
      transform: rotate(-45deg) translate(-50%, 0);
      transform-origin: top center;
    }
  }

  // Title / Price wrapper
  .content-inner {
    padding-right: 2.5rem;
    display: flex;
    flex-flow: column;
    height: 100%;
  }

  // Title
  .title {
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0;
    font-family: $font_heading;
    font-weight: bold;
    margin-bottom: 0.2rem;
  }

  .price {
    margin-top: auto;
  }
}

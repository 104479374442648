@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

$border-color: #000000;

.root {
  border-left: 20px solid $border-color;
  padding: 2rem;
  color: #ffffff;
  font-weight: bold;

  &.error {
    background-color: #6b1225;
  }

  &.warning {
    background-color: #6b5c12;
  }

  &.success {
    background-color: #3c6b12;
  }

  &.information {
    background-color: #ff9e31;
    color: #000000;
  }
}

@use 'sass:math';
@import '../../../styles/resources/colors';
@import '../../../styles/resources/variables';
@import '../../../styles/resources/mixins';

.root {
  position: relative;
  overflow: hidden;

  .content {
    padding: 8rem 2rem;

    @include page-width(xl-up) {
      padding: 8rem 3rem;
    }

    @include page-width(xxl-up) {
      padding: 10rem 3rem;
    }
  }
}

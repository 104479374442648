@use 'sass:math';
@import '../../../styles/resources/colors';
@import '../../../styles/resources/variables';
@import '../../../styles/resources/mixins';

.search-message {
  -webkit-animation: 1s ease 0s normal forwards 1 fadein;
  animation: 1s ease 0s normal forwards 1 fadein;

  @keyframes fadein{
    0% { opacity:0; }
    66% { opacity:0; }
    100% { opacity:1; }
  }

}
.root {
  position: relative;
  overflow: hidden;
  height: 55rem;


  @include page-width(sm-up) {
    height: 0;
    padding-bottom: 58%;
  }

  @include page-width(lg-up) {
    padding-bottom: 44%;
  }

  @include page-width(xl-up) {
    height: 55rem;
    padding-bottom: 0;
  }

  .content {
    position: relative;
    z-index: 4;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    padding: 2rem 0;

    &.invert {
      color: white;
    }

    @include page-width(sm-up) {
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      padding: 2rem 1rem;
    }

    @include page-width(xl-up) {
      position: relative;
      top: auto;
      left: auto;
      padding: 3rem;
    }
  }
}

@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: column;
}

.item {
  cursor: pointer;
  border-bottom: 1px solid black;
  padding: 1rem 0;

  > a {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.inner {
  height: 0;
  overflow: hidden;
  transition: height ease 0.2s;
}

.title-bold {
  > a {
    > span {
      text-transform: uppercase;
      font-weight: 900;
      @include size-h4();

      @include page-width(sm-down) {
        font-size: 1.6rem;
        line-height: 1.2;
      }
    }
  }
}

.arrow {
  transform: rotate(-45deg);
  margin-left: 1.5rem;
}

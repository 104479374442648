@use 'sass:math';
@import '../../../styles/resources/colors';
@import '../../../styles/resources/variables';
@import '../../../styles/resources/mixins';

.title {
  display: flex;
  align-items: center;
  border-bottom: 1px solid black;
  padding-bottom: 1.2rem;
  margin-bottom: 1rem;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.list-item {
  a {
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      transform: rotate(-45deg);
      margin-left: 1.5rem;
    }
  }
}

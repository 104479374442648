@use 'sass:math';
@import '../../../styles/resources/colors';
@import '../../../styles/resources/variables';
@import '../../../styles/resources/mixins';

.root {
  .item {
    position: relative;
    height: 70rem;

    @include page-width(sm-up) {
      height: 0;
      padding-bottom: 58%;
    }

    @include page-width(lg-up) {
      padding-bottom: 44%;
    }

    @include page-width(xl-up) {
      height: 70rem;
      padding-bottom: 0;
    }

    .content-wrap {
      position: relative;
      z-index: 4;
      width: 100%;
      height: 100%;

      @include page-width(sm-up) {
        position: absolute;
        top: 0;
        left: 0;
      }

      @include page-width(xl-up) {
        position: relative;
        top: auto;
        left: auto;
      }
    }

    .content {
      position: relative;
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      padding: 0 0 3.5rem;

      &.invert {
        color: white;
      }

      @include page-width(sm-up) {
        justify-content: center;
        padding: 2rem 0 2rem 1rem;
      }

      @include page-width(xl-up) {
        padding: 3rem 0 3rem 3rem;
      }
    }

    .content-images {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem 0 0;

      @include page-width(sm-up) {
        justify-content: flex-end;
        padding: 2rem 1rem;
      }

      @include page-width(xl-up) {
        padding: 3rem;
      }

      .content-image {
        transform: translate(15%, 0);

        @include page-width(sm-up) {
          transform: translate(30%, 0);
        }

        img {
          max-width: 100%;
          max-height: 100%;
        }

        &:nth-of-type(2) {
          img {
            margin-left: -30%;
            margin-top: 15%;
          }
        }
      }
    }
  }
}

@use 'sass:math';
@import '../../../../styles/resources/colors';
@import '../../../../styles/resources/variables';
@import '../../../../styles/resources/mixins';

.root {
  @include page-width(md-up) {
    &.vertical {
      display: flex;

      .navigation {
        flex-flow: column;
        max-width: none;
        overflow-x: initial;
        justify-content: flex-start;
      }

      .content {
        padding: 0 2rem;
        flex: 0 1 100%;
      }
    }

    &.large {
      .tab {
        button {
          font-family: $font_heading;
          @include size-h5();
          padding-left: 7rem;
          padding-right: 7rem;
        }
      }
    }
  }
}

.navigation {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;

  @include page-width(md-up) {
    flex-wrap: nowrap;
    justify-content: center;
  }
}

.content {
  padding: 2rem 0;
}

.tab {
  position: relative;
  flex: 0 1 100%;
  // margin-top: -2px;

  @include page-width(md-up) {
    // margin-top: 0;
    flex: 0;
  }

  button {
    border: 0;
    margin: 0;
    background-color: transparent;
    border-style: solid;
    border-width: 2px;
    border-color: theme-color('light-grey');
    cursor: pointer;
    color: black;
    width: 100%;

    @include page-width(sm-down) {
      padding: 1rem;
    }

    @include page-width(md-up) {
      border-top-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom-color: theme-color('light-grey');
    }
  }

  &.active {
    button {
      border-color: theme-color('primary');
    }

    @include page-width(md-up) {
      button {
        border-color: transparent;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: $color_primary;
      }
    }
  }
}
